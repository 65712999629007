<template>
  <mobile-screen :header="true" screen-class="action.js-screen">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="actions-header-menu icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name:
                $route.params &&
                $route.params.one_click &&
                $route.params.one_click === 'true'
                  ? 'r_one-click'
                  : 'r_plan'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("declarations", "declaration", "declare") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <section class="actions-top" v-if="declaration && declaration.data">
      <section class="action-location-info">
        <svg-icon icon="location"></svg-icon>
        <ul class="location-info">
          <li v-if="allParents && allParents.length">
            {{ displayParentNames(allParents) }}
          </li>
          <li v-else>{{ declaration.data.resource.name }}</li>
          <li v-if="declaration.data.status">
            {{ declaration.data.status.name.toUpperCase() }}
          </li>
        </ul>
      </section>
      <section class="actions-check-in-out">
        <button
          class="body-btn"
          v-if="preCheckIn && status && !startedStatuses.includes(status)"
          :disabled="
            !canCheckIn || tempInterval || endedStatuses.includes(status)
          "
          @click="
            declarationCheckIn({ id: declaration.data.id, refresh: true })
          "
        >
          {{ displayLabelName("declarations", "declaration", "i-am-here") }}
        </button>
        <button
          v-if="preCheckIn && status && startedStatuses.includes(status)"
          class="body-btn"
          :disabled="tempInterval"
          @click="declarationCheckOut(declaration.data.id)"
        >
          {{ displayLabelName("declarations", "declaration", "i-am-leaving") }}
        </button>
      </section>
    </section>
    <section class="actions-bottom">
      <section class="actions-time-left processing" v-if="tempInterval">
        <h3 class="label">
          {{ displayLabelName("declarations", "declaration", "processing") }}
        </h3>
        <div class="time active" v-if="tempTimer">
          <time>{{ tempTimer.format("HH:mm:ss") }}</time>
        </div>
      </section>
      <section class="actions-time-left processing" v-else>
        <h3
          class="label"
          :class="{ active: status && startedStatuses.includes(status) }"
        >
          {{ displayLabelName("declarations", "declaration", "time-left") }}
        </h3>
        <div
          class="time"
          v-if="meetingDuration"
          :class="{ active: status && startedStatuses.includes(status) }"
        >
          <time>{{ meetingDuration.format("HH:mm:ss") }}</time>
        </div>
      </section>
      <section
        class="actions-extend-time"
        v-if="extendSlots && extendSlots.length"
      >
        <div class="actions-extend-time-title">
          <svg-icon icon="extend"></svg-icon>
          <h3>
            {{ displayLabelName("declarations", "declaration", "extend-time") }}
          </h3>
        </div>
        <ul
          class="extend-time-list"
          :class="{
            active: status && startedStatuses.includes(status) && !tempInterval
          }"
        >
          <li
            class="extend-time-item"
            v-for="slot in extendSlots"
            :key="slot.duration"
          >
            <button
              class="extend-time-button"
              :disabled="
                (status && !startedStatuses.includes(status)) ||
                  !status ||
                  tempInterval
              "
              @click="
                declarationExtendTime({
                  id: declaration.data.id,
                  type: slot.extending_type
                })
              "
            >
              {{ formatDate(slot.duration) }}
            </button>
          </li>
        </ul>
      </section>
    </section>
  </mobile-screen>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import dayjs from "dayjs";

export default {
  name: "Action",
  data() {
    return {
      timer: null,
      startedStatuses: [
        "STARTED",
        "CHECKED_IN",
        "EXPIRING_WARNING",
        "EXTENDED"
      ],
      endedStatuses: ["ENDED", "AUTO_RELEASED", "CHECKED_OUT"],
      time: dayjs(),
      meetingDuration: null,
      canCheckIn: null,
      canStart: null,
      canEnd: null,
      canAutoRelease: null,
      callInterval: null,
      reloadStatus: null,
      tempTimer: dayjs.duration({ hours: 0, minutes: 0, seconds: 45 }),
      tempInterval: null
    };
  },
  computed: {
    ...mapState("declaration", ["declaration"]),
    allParents() {
      const { declaration } = this;
      if (declaration && declaration.data) {
        const { resource } = declaration.data;
        return resource && resource.level && resource.level.all_parents;
      }
      return null;
    },
    extendSlots() {
      const { declaration } = this;
      if (declaration && declaration.data) {
        const { extending_slots } = declaration.data;
        return extending_slots && extending_slots.length && extending_slots;
      }
      return null;
    },
    /**
     * Current time computed property
     * Used for timer.
     * Get method returns time - dayjs object
     * Set method increases time by one second
     */
    currentTime: {
      get() {
        const { declaration } = this;
        if (declaration && declaration.data) {
          return this.time;
        }
        return null;
      },
      set(val) {
        this.time = dayjs().add(val, "second");
      }
    },
    declarationStartTime() {
      const { declaration } = this;
      if (declaration && declaration.data) {
        return dayjs(declaration.data.datetime_from);
      }
      return null;
    },
    declarationEndTime() {
      const { declaration } = this;
      if (declaration && declaration.data) {
        return dayjs(declaration.data.datetime_to);
      }
      return null;
    },
    preCheckIn() {
      const { declaration } = this;
      if (declaration && declaration.data && declaration.data.pre_check_in) {
        return dayjs(declaration.data.pre_check_in);
      }
      return null;
    },
    autoRelease() {
      const { declaration } = this;
      if (declaration && declaration.data && declaration.data.auto_release) {
        return dayjs(declaration.data.auto_release);
      }
      return null;
    },
    status() {
      /*
      return [
          'id'       => $this->id,
          'status'   => $this->status,
          'name'     => $this->name,
      ];
       */
      const { declaration } = this;
      if (declaration && declaration.data && declaration.data.status) {
        return declaration.data.status.status;
      }
      return null;
    }
  },
  watch: {
    declaration() {
      this.$nextTick(() => {
        // when declaration is retrieved fom the api set current time to
        // then set initial duration and clear interval
        this.time = dayjs();
        this.setInitialDuration();
        if (this.reloadStatus && this.reloadStatus !== this.status) {
          // reset call interval
          clearInterval(this.callInterval);
          this.callInterval = null;
          // reset temp interval
          clearInterval(this.tempInterval);
          this.tempInterval = null;
          this.tempTimer = dayjs.duration({
            hours: 0,
            minutes: 0,
            seconds: 45
          });
        }
        // if meeting duration is in minus exit watcher
        if (this.meetingDuration && this.meetingDuration.$ms < 0) {
          return;
        }
        this.setAction();
        clearInterval(this.timer);
        // start timer only if the declaration time is not ended
        if (this.status && !this.endedStatuses.includes(this.status)) {
          this.timer = setInterval(() => {
            // increases current time by 1 second
            this.currentTime = 1;
            if (
              this.startedStatuses.includes(this.status)
              // this.meetingDuration.format("HH:mm:ss") !== "00:00:00"
            ) {
              // countdown meeting duration by one second if !== 00:00:00
              if (this.meetingDuration.$ms > 0) {
                this.meetingDuration = this.meetingDuration.subtract(
                  1,
                  "second"
                );
              }
            }
            this.setAction();
          }, 1000);
        }
      });
    },
    canAutoRelease() {
      // if true and not ended and not started
      if (
        this.canAutoRelease &&
        !this.startedStatuses.includes(this.status) &&
        !this.endedStatuses.includes(this.status)
      ) {
        this.waitAndReload();
      }
    },
    canStart() {
      // if true and not started and not ended start it
      if (
        this.canStart &&
        !this.startedStatuses.includes(this.status) &&
        !this.endedStatuses.includes(this.status)
      ) {
        this.waitAndReload();
      }
    },
    canEnd() {
      // if true and not ended start it
      if (this.canEnd && !this.endedStatuses.includes(this.status)) {
        this.waitAndReload();
      }
    }
  },
  created() {
    this.getDeclaration({
      id: this.$route.params.declaration_id,
      params: {
        includes: ["extending_slots", "all_parents", "status"]
      }
    });
  },
  methods: {
    ...mapActions("declaration", [
      "getDeclaration",
      "declarationCheckIn",
      "declarationCheckOut",
      "declarationExtendTime"
    ]),
    displayParentNames(parents) {
      const filteredParents = parents.filter(item => item.name);
      return filteredParents.map(parent => parent.name).join(", ");
    },
    formatDate(date) {
      let parsedDate = dayjs.duration(date);
      if (Number(parsedDate.$d.hours)) {
        return (
          dayjs.duration({ hours: parsedDate.$d.hours }).format("H") + "Hrs"
        );
      } else if (Number(parsedDate.$d.minutes)) {
        return (
          dayjs.duration({ minutes: parsedDate.$d.minutes }).format("m") + "Min"
        );
      }
    },
    waitAndReload() {
      this.setTempTimer();
      this.callInterval = setInterval(() => {
        this.getDeclaration({
          id: this.$route.params.declaration_id,
          params: {
            includes: ["extending_slots", "all_parents", "status"]
          },
          hideLoader: true
        });
      }, 5000);
    },
    setInitialDuration() {
      const { declaration } = this;
      let meetingDuration;
      if (this.status) {
        if (this.startedStatuses.includes(this.status)) {
          meetingDuration = dayjs.duration(
            this.declarationEndTime.diff(this.currentTime)
          );
        } else {
          meetingDuration = dayjs.duration(declaration.data.duration);
        }
      } else {
        if (declaration && declaration.data) {
          meetingDuration = dayjs.duration(declaration.data.duration);
        }
      }

      if (meetingDuration && meetingDuration.$ms > 0) {
        this.meetingDuration = meetingDuration;
      }
    },
    setAction() {
      if (this.status) {
        // not started or ended
        if (this.preCheckIn) {
          if (this.autoRelease) {
            this.canCheckIn = this.currentTime.isSameOrAfter(this.preCheckIn);
            this.canAutoRelease =
              this.currentTime.isSameOrAfter(this.autoRelease) &&
              this.canCheckIn;
          } else {
            this.canCheckIn = this.currentTime.isSameOrAfter(this.preCheckIn);
          }
        } else {
          this.canStart = this.currentTime.isSameOrAfter(
            this.declarationStartTime
          );
        }
        this.canEnd = this.currentTime.isSameOrAfter(this.declarationEndTime);
        this.reloadStatus = this.status;
      }
    },
    setTempTimer() {
      this.tempInterval = setInterval(() => {
        if (this.tempTimer.$ms > 0) {
          this.tempTimer = this.tempTimer.subtract(1, "second");
        }
      }, 1000);
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
    clearInterval(this.callInterval);
    clearInterval(this.tempInterval);
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
